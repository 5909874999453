
import * as cloudinaryRuntime$5N3gnOHvvW from '/opt/buildhome/repo/node_modules/@nuxt/image-edge/dist/runtime/providers/cloudinary'
import * as ipxRuntime$g7r1DTxiGq from '/opt/buildhome/repo/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1980,
    "3xl": 2400,
    "4xl": 2560
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$5N3gnOHvvW, defaults: {"baseURL":"https://res.cloudinary.com/deetpv8w0/image/upload/"} },
  ['ipx']: { provider: ipxRuntime$g7r1DTxiGq, defaults: {} }
}
        