import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_OrkQhMqHci from "/opt/buildhome/repo/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import aos_client_Gr50AN4Ul1 from "/opt/buildhome/repo/plugins/aos.client.js";
import google_recaptcha_rYK9Kziv68 from "/opt/buildhome/repo/plugins/google-recaptcha.ts";
import lottie_client_funiu1oBjt from "/opt/buildhome/repo/plugins/lottie.client.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_OrkQhMqHci,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  aos_client_Gr50AN4Ul1,
  google_recaptcha_rYK9Kziv68,
  lottie_client_funiu1oBjt
]