import { default as aboutusrkXxtBDZdMMeta } from "/opt/buildhome/repo/pages/aboutus.vue?macro=true";
import { default as _91slug_93x8nwzxMykbMeta } from "/opt/buildhome/repo/pages/blog/[slug].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as dataprotectionld8YLE16uuMeta } from "/opt/buildhome/repo/pages/dataprotection.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as solutioncksjKNw10mMeta } from "/opt/buildhome/repo/pages/solution.vue?macro=true";
import { default as _91item_93Lrq5LnhkMsMeta } from "/opt/buildhome/repo/pages/solutions/[item].vue?macro=true";
import { default as indexnJKARC5ZtiMeta } from "/opt/buildhome/repo/pages/solutions/index.vue?macro=true";
export default [
  {
    name: aboutusrkXxtBDZdMMeta?.name ?? "aboutus___en___default",
    path: aboutusrkXxtBDZdMMeta?.path ?? "/aboutus",
    meta: aboutusrkXxtBDZdMMeta || {},
    alias: aboutusrkXxtBDZdMMeta?.alias || [],
    redirect: aboutusrkXxtBDZdMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/aboutus.vue").then(m => m.default || m)
  },
  {
    name: aboutusrkXxtBDZdMMeta?.name ?? "aboutus___en",
    path: aboutusrkXxtBDZdMMeta?.path ?? "/en/aboutus",
    meta: aboutusrkXxtBDZdMMeta || {},
    alias: aboutusrkXxtBDZdMMeta?.alias || [],
    redirect: aboutusrkXxtBDZdMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/aboutus.vue").then(m => m.default || m)
  },
  {
    name: aboutusrkXxtBDZdMMeta?.name ?? "aboutus___de",
    path: aboutusrkXxtBDZdMMeta?.path ?? "/de/ueber-uns",
    meta: aboutusrkXxtBDZdMMeta || {},
    alias: aboutusrkXxtBDZdMMeta?.alias || [],
    redirect: aboutusrkXxtBDZdMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/aboutus.vue").then(m => m.default || m)
  },
  {
    name: aboutusrkXxtBDZdMMeta?.name ?? "aboutus___tr",
    path: aboutusrkXxtBDZdMMeta?.path ?? "/tr/hakk%C4%B1m%C4%B1zda",
    meta: aboutusrkXxtBDZdMMeta || {},
    alias: aboutusrkXxtBDZdMMeta?.alias || [],
    redirect: aboutusrkXxtBDZdMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/aboutus.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x8nwzxMykbMeta?.name ?? "blog-slug___en___default",
    path: _91slug_93x8nwzxMykbMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93x8nwzxMykbMeta || {},
    alias: _91slug_93x8nwzxMykbMeta?.alias || [],
    redirect: _91slug_93x8nwzxMykbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x8nwzxMykbMeta?.name ?? "blog-slug___en",
    path: _91slug_93x8nwzxMykbMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_93x8nwzxMykbMeta || {},
    alias: _91slug_93x8nwzxMykbMeta?.alias || [],
    redirect: _91slug_93x8nwzxMykbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x8nwzxMykbMeta?.name ?? "blog-slug___de",
    path: _91slug_93x8nwzxMykbMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93x8nwzxMykbMeta || {},
    alias: _91slug_93x8nwzxMykbMeta?.alias || [],
    redirect: _91slug_93x8nwzxMykbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x8nwzxMykbMeta?.name ?? "blog-slug___tr",
    path: _91slug_93x8nwzxMykbMeta?.path ?? "/tr/blog/:slug()",
    meta: _91slug_93x8nwzxMykbMeta || {},
    alias: _91slug_93x8nwzxMykbMeta?.alias || [],
    redirect: _91slug_93x8nwzxMykbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnCitm8tluDMeta?.name ?? "blog___en___default",
    path: indexnCitm8tluDMeta?.path ?? "/blog",
    meta: indexnCitm8tluDMeta || {},
    alias: indexnCitm8tluDMeta?.alias || [],
    redirect: indexnCitm8tluDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexnCitm8tluDMeta?.name ?? "blog___en",
    path: indexnCitm8tluDMeta?.path ?? "/en/blog",
    meta: indexnCitm8tluDMeta || {},
    alias: indexnCitm8tluDMeta?.alias || [],
    redirect: indexnCitm8tluDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexnCitm8tluDMeta?.name ?? "blog___de",
    path: indexnCitm8tluDMeta?.path ?? "/de/blog",
    meta: indexnCitm8tluDMeta || {},
    alias: indexnCitm8tluDMeta?.alias || [],
    redirect: indexnCitm8tluDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexnCitm8tluDMeta?.name ?? "blog___tr",
    path: indexnCitm8tluDMeta?.path ?? "/tr/blog",
    meta: indexnCitm8tluDMeta || {},
    alias: indexnCitm8tluDMeta?.alias || [],
    redirect: indexnCitm8tluDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact___en___default",
    path: contactupLWW5VsuQMeta?.path ?? "/contact",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact___en",
    path: contactupLWW5VsuQMeta?.path ?? "/en/contact",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact___de",
    path: contactupLWW5VsuQMeta?.path ?? "/de/kontakt",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact___tr",
    path: contactupLWW5VsuQMeta?.path ?? "/tr/iletisim",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: dataprotectionld8YLE16uuMeta?.name ?? "dataprotection___en___default",
    path: dataprotectionld8YLE16uuMeta?.path ?? "/dataprotection",
    meta: dataprotectionld8YLE16uuMeta || {},
    alias: dataprotectionld8YLE16uuMeta?.alias || [],
    redirect: dataprotectionld8YLE16uuMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/dataprotection.vue").then(m => m.default || m)
  },
  {
    name: dataprotectionld8YLE16uuMeta?.name ?? "dataprotection___en",
    path: dataprotectionld8YLE16uuMeta?.path ?? "/en/dataprotection",
    meta: dataprotectionld8YLE16uuMeta || {},
    alias: dataprotectionld8YLE16uuMeta?.alias || [],
    redirect: dataprotectionld8YLE16uuMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/dataprotection.vue").then(m => m.default || m)
  },
  {
    name: dataprotectionld8YLE16uuMeta?.name ?? "dataprotection___de",
    path: dataprotectionld8YLE16uuMeta?.path ?? "/de/dataprotection",
    meta: dataprotectionld8YLE16uuMeta || {},
    alias: dataprotectionld8YLE16uuMeta?.alias || [],
    redirect: dataprotectionld8YLE16uuMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/dataprotection.vue").then(m => m.default || m)
  },
  {
    name: dataprotectionld8YLE16uuMeta?.name ?? "dataprotection___tr",
    path: dataprotectionld8YLE16uuMeta?.path ?? "/tr/dataprotection",
    meta: dataprotectionld8YLE16uuMeta || {},
    alias: dataprotectionld8YLE16uuMeta?.alias || [],
    redirect: dataprotectionld8YLE16uuMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/dataprotection.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en___default",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en",
    path: indexniDiYCWjuTMeta?.path ?? "/en",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___de",
    path: indexniDiYCWjuTMeta?.path ?? "/de",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___tr",
    path: indexniDiYCWjuTMeta?.path ?? "/tr",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: solutioncksjKNw10mMeta?.name ?? "solution___en___default",
    path: solutioncksjKNw10mMeta?.path ?? "/solution",
    meta: solutioncksjKNw10mMeta || {},
    alias: solutioncksjKNw10mMeta?.alias || [],
    redirect: solutioncksjKNw10mMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solution.vue").then(m => m.default || m)
  },
  {
    name: solutioncksjKNw10mMeta?.name ?? "solution___en",
    path: solutioncksjKNw10mMeta?.path ?? "/en/solution",
    meta: solutioncksjKNw10mMeta || {},
    alias: solutioncksjKNw10mMeta?.alias || [],
    redirect: solutioncksjKNw10mMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solution.vue").then(m => m.default || m)
  },
  {
    name: solutioncksjKNw10mMeta?.name ?? "solution___de",
    path: solutioncksjKNw10mMeta?.path ?? "/de/solution",
    meta: solutioncksjKNw10mMeta || {},
    alias: solutioncksjKNw10mMeta?.alias || [],
    redirect: solutioncksjKNw10mMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solution.vue").then(m => m.default || m)
  },
  {
    name: solutioncksjKNw10mMeta?.name ?? "solution___tr",
    path: solutioncksjKNw10mMeta?.path ?? "/tr/solution",
    meta: solutioncksjKNw10mMeta || {},
    alias: solutioncksjKNw10mMeta?.alias || [],
    redirect: solutioncksjKNw10mMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solution.vue").then(m => m.default || m)
  },
  {
    name: _91item_93Lrq5LnhkMsMeta?.name ?? "solutions-item___en___default",
    path: _91item_93Lrq5LnhkMsMeta?.path ?? "/solutions/:item",
    meta: _91item_93Lrq5LnhkMsMeta || {},
    alias: _91item_93Lrq5LnhkMsMeta?.alias || [],
    redirect: _91item_93Lrq5LnhkMsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93Lrq5LnhkMsMeta?.name ?? "solutions-item___en",
    path: _91item_93Lrq5LnhkMsMeta?.path ?? "/en/solutions/:item",
    meta: _91item_93Lrq5LnhkMsMeta || {},
    alias: _91item_93Lrq5LnhkMsMeta?.alias || [],
    redirect: _91item_93Lrq5LnhkMsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93Lrq5LnhkMsMeta?.name ?? "solutions-item___de",
    path: _91item_93Lrq5LnhkMsMeta?.path ?? "/de/leistungen/:item",
    meta: _91item_93Lrq5LnhkMsMeta || {},
    alias: _91item_93Lrq5LnhkMsMeta?.alias || [],
    redirect: _91item_93Lrq5LnhkMsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93Lrq5LnhkMsMeta?.name ?? "solutions-item___tr",
    path: _91item_93Lrq5LnhkMsMeta?.path ?? "/tr/cozumlerimiz/:item",
    meta: _91item_93Lrq5LnhkMsMeta || {},
    alias: _91item_93Lrq5LnhkMsMeta?.alias || [],
    redirect: _91item_93Lrq5LnhkMsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/[item].vue").then(m => m.default || m)
  },
  {
    name: indexnJKARC5ZtiMeta?.name ?? "solutions___en___default",
    path: indexnJKARC5ZtiMeta?.path ?? "/solutions",
    meta: indexnJKARC5ZtiMeta || {},
    alias: indexnJKARC5ZtiMeta?.alias || [],
    redirect: indexnJKARC5ZtiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJKARC5ZtiMeta?.name ?? "solutions___en",
    path: indexnJKARC5ZtiMeta?.path ?? "/en/solutions",
    meta: indexnJKARC5ZtiMeta || {},
    alias: indexnJKARC5ZtiMeta?.alias || [],
    redirect: indexnJKARC5ZtiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJKARC5ZtiMeta?.name ?? "solutions___de",
    path: indexnJKARC5ZtiMeta?.path ?? "/de/solutions",
    meta: indexnJKARC5ZtiMeta || {},
    alias: indexnJKARC5ZtiMeta?.alias || [],
    redirect: indexnJKARC5ZtiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJKARC5ZtiMeta?.name ?? "solutions___tr",
    path: indexnJKARC5ZtiMeta?.path ?? "/tr/solutions",
    meta: indexnJKARC5ZtiMeta || {},
    alias: indexnJKARC5ZtiMeta?.alias || [],
    redirect: indexnJKARC5ZtiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/solutions/index.vue").then(m => m.default || m)
  }
]