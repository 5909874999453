import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app/nuxt'

const components = {}

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const name in components) {
      nuxtApp.vueApp.component(name, components[name])
      nuxtApp.vueApp.component('Lazy' + name, components[name])
    }
  }
})
